
import React from 'react';
import './App.css';
//import Navbar from './components/Navbar';
//import Navbar from './components/Navbarelements/index'
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
//import Home from './pages';
import Loyaltyrewards from './components/pages/loyaltrewards'
import Chat from './components/pages/order-chat'
function App() {
  return (

      <Router>
    
      <Routes>
         
            
          <Route path='/:id' element={<Chat/>}/>
          
          <Route path='' element={<Chat/>}/>

      </Routes>
      </Router>
  );
  }
    
  export default App;