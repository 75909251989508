import React, { useState, useEffect } from 'react';
import { Button, Container, Rating, Typography } from '@mui/material';
//import firebase from 'firebase/app';
//import 'firebase/auth';
//import 'firebase/functions';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import axios from 'axios';
function App() {
  const [valueForMoney, setValueForMoney] = useState(0);
  const [speedOfDelivery, setSpeedOfDelivery] = useState(0);
  const [quality, setQuality] = useState(0);
  const [thanksView,setthanksview]=useState(false)
  const [labels, setData] = useState([]);
  const [ratings, setRatings] = useState({});


  
 
  return (
  
    <Container className={'container'}>
       
        <div className="ratingContainer">
          <Typography variant="div" className={'ratingTitle'}>
          Feature development in progress
          </Typography>
         
      </div>
      
    </Container>
    
  );
};

export default App;